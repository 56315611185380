import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Card from '@mui/material/Card';
import DownloadData from './DownloadData';

const UserDetails = ({ allUsers }) => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await Promise.all(
        allUsers.map(async (item) => {
          const user = await axios(API_URL + '/user', {
            params: { userId: item.user_id },
          });
          return { ...item, userDetails: user?.data?.data };
        })
      );
      setUserData(data);
      setLoading(false);
    };

    fetchData();
  }, [allUsers]);

  return (
    <div>
      {loading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'>
          <CircularProgress />
        </Box>
      )}
      {userData.length && (
        <>
          <DownloadData userData={userData} />
          <TableContainer component={Card}>
            <Table
              sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
              aria-label='table'>
              <TableHead>
                <TableRow>
                  <TableCell>Sr No.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align='left'>Email</TableCell>
                  <TableCell align='left'>Quiz Score</TableCell>
                  <TableCell align='left'>Basic Resume</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((user, index) => {
                  return (
                    <TableRow
                      key={user._id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell component='th' scope='row'>
                        {index + 1}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {user?.userDetails?.firstName}{' '}
                        {user?.userDetails?.lastName}
                      </TableCell>
                      <TableCell align='left'>
                        {user?.userDetails?.email}
                      </TableCell>
                      <TableCell align='left'>{user?.quiz_score}%</TableCell>
                      <TableCell align='left'>
                        {' '}
                        {user.basic_cv && (
                          <a
                            href={user.basic_cv}
                            target='_blank'
                            rel='noreferrer'>
                            Basic cv
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default UserDetails;
