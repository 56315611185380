import { Button, Stack } from '@mui/material';
import React from 'react';
import { ExportJsonCsv } from 'react-export-json-csv';

const DownloadData = ({ userData }) => {
  console.log(userData);
  const finalData = userData.map((el) => {
    const tempObj = {
      ...el,
      Name: el.userDetails.firstName,
      Email: el.userDetails.email,
    };
    delete tempObj.userDetails;
    delete tempObj.created_at;
    return tempObj;
  });
  console.log(finalData);

  const headers =
    finalData.length > 0
      ? Object.keys(finalData[0]).map((el) => {
          return { key: el, name: el };
        })
      : [];

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={1}
        mb={2}>
        {finalData.length > 0 && (
          <ExportJsonCsv headers={headers} items={finalData}>
            <Button variant='outlined'>User Data</Button>
          </ExportJsonCsv>
        )}
      </Stack>
    </div>
  );
};

export default DownloadData;
