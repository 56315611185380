import { Box, CircularProgress } from '@mui/material';
import { supabase } from 'links/supabaseConfig';
import React, { useEffect, useState } from 'react';
import UserDetails from './UserDetails';

const Index = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase.from('fiib_users').select();

      if (fetchedData) {
        setAllUsers(fetchedData);
        setLoading(false);
      }
    };

    setDataFn();
  }, []);

  return (
    <div>
      {loading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'>
          <CircularProgress />
        </Box>
      )}
      {allUsers.length > 0 && <UserDetails allUsers={allUsers} />}
    </div>
  );
};

export default Index;
